import React, { useState } from "react"
import CheckoutSuccessPopup from "./CheckoutSuccessPopup"
import ContactAvatar from "../contacts/ContactAvatar"
import moment from "moment"
import PaymentMethodModal from "../account-settings/payment-methods/PaymentMethodModal"
import PromoCodeInput from "./PromocodeInput"
import SessionCheckout from "./SessionCheckout"
import useDate from "../../hooks/useDate"
import useLoading from "../../hooks/useLoading"
import useScrollToTop from "../../hooks/useScrollToTop"
import { Button } from "../ui/button"
import Loader from "../ui/loader"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  PaymentRequests,
  ServiceRequests,
  ContactRequests,
  MeetingRequests,
} from "../../api/app.service"
import { pluralize } from "../../utils/pluralize"
import {
  SvgBundle,
  SvgCheck,
  SvgCheckCircle,
  SvgCheckbox,
  SvgCheckboxOff,
  SvgConsultation,
  SvgLock2,
  SvgPayment,
  SvgPiechartOneQuarter,
  SvgPiechartThreeQuarters,
  SvgRecurring,
  SvgUserCheck,
} from "../icons/"
import { useLocation } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import { getUnits } from "../../utils/services/get-units/get-units"
import { getPrice } from "../../utils/services/get-price"

interface Props {
  initialSize?: string
}

const ProgramCheckout: React.FC<Props> = ({ initialSize }) => {
  const [contacts, setContacts] = React.useState<any>(null)
  const [activeService, setActiveService] = React.useState<any>(null)
  const [activeContact, setActiveContact] = React.useState<any>(null)
  const [options, setOptions] = React.useState<any>({})
  const [meetingDetails, setMeetingDetails] = React.useState<any>(null)
  const [serviceUsageId, setServiceUsageId] = React.useState<any>(null)
  const [clientSecret, setClientSecret] = React.useState<any>(null)
  const [connectedAccountID, setConnectedAccountID] = React.useState<any>(null)
  const [checkoutSuccessPopup, setCheckoutSuccessPopup] = React.useState<any>({
    show: false,
    state: {},
  })
  const location = useLocation()

  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [paymentMethods, setPaymentMethods] = React.useState<any[]>([])
  const [paymentMethodModal, setPaymentMethodModal] =
    React.useState<boolean>(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<any>(
    {}
  )
  const [isTermsAgreed, setIsTermsAgreed] = React.useState<boolean>(false)
  const [addPromoCode, setAddPromoCode] = React.useState<boolean>(false)
  const [appliedDiscount, setAppliedDiscount] = React.useState<any>({
    amount: 0,
    promoCode: "",
  })
  const [promoCode, setPromoCode] = React.useState<string>("")
  const { mdDate } = useDate()

  const getUserTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const longTimezoneName = new Date().toLocaleTimeString("en", {
      timeZoneName: "long",
    })
    const extractedTimezone = longTimezoneName.split(" ")[2]
    const timezoneWithTime = `${extractedTimezone} Time`
    let timezoneDisplay = timezoneWithTime.replace("_", " ")
    timezoneDisplay = timezoneDisplay.replace("/", " & ")
    timezoneDisplay = `${timezoneDisplay} - ${timezone}`
    return timezoneDisplay
  }

  const userTimezone = getUserTimezone()
  const [showCheckout, setShowCheckout] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  // Event handler for the button click
  const handleClick = () => {
    setShowCheckout(true)
  }

  const backToContactProfile = () => {
    window.location.href = `/${user.activeProfile}/contacts/${activeContact?.profile_id}`
  }

  const getExpirationDate = () => {
    const expDate = moment(new Date()).add(
      activeService?.service_details?.expiration?.time,
      activeService?.service_details?.expiration.type
    )
    return moment(expDate).format("MMMM DD, yyyy")
  }

  const getExpirationText = () => {
    let expText: string = ""
    if (
      ["individual", "consultation", "bundle"].includes(
        activeService?.service_details?.type
      )
    ) {
      expText = "Your session(s) must be used no later than"
    } else if (activeService?.service_details?.type === "recurring") {
      expText = "This service will be recurring until"
    }
    return expText
  }

  const getServiceType = () => {
    if (activeService?.service_details?.type === "consultation") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgConsultation className="text-blurple" />
          Consultation
        </div>
      )
    } else if (activeService?.service_details?.type === "individual") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgUserCheck className="text-blurple" />
          Individual Session
        </div>
      )
    } else if (activeService?.service_details?.type === "bundle") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgBundle className="text-blurple" />
          Bundle Package
        </div>
      )
    } else if (activeService?.service_details?.type === "recurring") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgRecurring />
          Recurring Program
        </div>
      )
    }
  }

  const getInstallmentAmounts = () => {
    const percent =
      activeService?.service_details?.billing?.upFrontPercentage / 100
    const amount = parseInt(activeService?.service_details?.billing?.amount)
    const upfront = amount * percent
    const installment =
      (amount - upfront) /
      activeService?.service_details?.billing?.installmentIncrements?.length
    return {
      upfront: `$${upfront.toFixed(2)}`,
      installment: `$${installment.toFixed(2)}`,
    }
  }

  const getRecurringTotal = () => {
    const recurringCost = parseInt(
      activeService?.service_details?.billing?.amount
    )
    const recurringTime = parseInt(
      activeService?.service_details?.expiration?.time
    )
    const totalRecurringCost = recurringCost * recurringTime
    const discountAmount = appliedDiscount?.amount
      ? parseFloat(appliedDiscount.amount)
      : 0
    const totalCostAfterDiscount = totalRecurringCost - discountAmount
    const finalTotalCost =
      totalCostAfterDiscount < 0 ? 0 : totalCostAfterDiscount
    return finalTotalCost.toFixed(2)
  }

  const getHeaderText = () => {
    if (activeService?.service_details?.type === "consultation") {
      if (parseInt(activeService?.service_details?.billing?.amount) > 0) {
        return "Consulation Details"
      } else {
        return "Consultation Details"
      }
    } else {
      return "Service Checkout"
    }
  }

  const getDetailsText = () => {
    if (activeService?.service_details?.type === "consultation") {
      return "Coach Details"
    } else {
      return "Meeting Details"
    }
  }

  const getPaymentDetails = () => {
    switch (activeService?.service_details?.type) {
      case "free":
        break
      case "individual":
        return (
          <>
            <div className="mb-[62px] mt-[24px] flex items-center">
              <div className="flex flex-col">
                <div className="border-grayMist text-blurple flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                  <SvgCheck className="h-4 w-4" />
                </div>
                <p className="min-w-fit text-base">Select coach</p>
              </div>
              <div className="border-grayCloud mx-[24px] mb-[32px] h-[2px] w-full border-b border-dashed" />
              <div className="flex flex-col">
                <div className="border-grayMist text-blurple flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                  <SvgCheck className="h-4 w-4" />
                </div>
                <p className="min-w-fit text-base">Select program</p>
              </div>
              <div className="border-grayCloud mx-[24px] mb-[32px] h-[2px] w-full border-b border-dashed" />
              <div className="flex flex-col">
                <div className="border-blurple text-blurple flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                  <SvgPayment />
                </div>
                <p className="min-w-fit text-base">Checkout</p>
              </div>
            </div>
            {/* <div className="h-[1px] w-full bg-grayMist mb-[24px]" /> */}
            {activeService?.service_details?.allow_promo && (
              <PromoCodeInput
                addPromoCode={addPromoCode}
                appliedDiscount={appliedDiscount}
                setAddPromoCode={setAddPromoCode}
                promoCode={promoCode}
                handlePromoCodeChange={handlePromoCodeChange}
                validatePromoCode={validatePromoCode}
                symbol={activeService?.service_details?.symbol}
              />
            )}
            <div className="mb-[8px] flex items-center">
              <p className="min-w-fit text-base">Individual Session</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              <p className="min-w-fit text-base">
                {activeService &&
                  `${String.fromCharCode(
                    parseInt(
                      activeService?.service_details?.symbol?.slice(2),
                      16
                    )
                  )}${parseInt(
                    activeService?.service_details?.billing?.amount
                  ).toFixed(2)}`}
              </p>
            </div>
            <div className="mb-[8px] flex items-center">
              <p className="min-w-fit text-base">Interest or fees</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              {/* TODO make dynamice */}
              <p className="min-w-fit text-base">
                {" "}
                {activeService &&
                  String.fromCharCode(
                    parseInt(
                      activeService?.service_details?.symbol?.slice(2),
                      16
                    )
                  )}
                0.00
              </p>
            </div>
            <div className="mb-[24px] flex items-center">
              <p className="min-w-fit text-[18px] font-bold">Order total</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              {/* TODO make dynamice */}
              <p className="min-w-fit text-base font-bold">
                {activeService &&
                  `${String.fromCharCode(
                    parseInt(
                      activeService?.service_details?.symbol?.slice(2),
                      16
                    )
                  )}${(
                    parseInt(activeService?.service_details?.billing?.amount) -
                    parseInt(appliedDiscount.amount)
                  ).toFixed(2)}`}
              </p>
            </div>
          </>
        )
      case "consultation":
        return (
          <>
            <div className="mb-[62px] mt-[24px] flex items-center">
              <div className="flex flex-col">
                <div className="border-grayMist text-blurple flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                  <SvgCheck className="h-4 w-4" />
                </div>
                <p className="min-w-fit text-base">Select coach</p>
              </div>
              <div className="border-grayCloud mx-[24px] mb-[32px] h-[2px] w-full border-b border-dashed" />
              <div className="flex flex-col">
                <div className="border-grayMist text-blurple flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                  <SvgCheck className="h-4 w-4" />
                </div>
                <p className="min-w-fit text-base">Select program</p>
              </div>
              <div className="border-grayCloud mx-[24px] mb-[32px] h-[2px] w-full border-b border-dashed" />
              <div className="flex flex-col">
                <div className="border-blurple text-blurple flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                  <SvgPayment />
                </div>
                <p className="min-w-fit text-base">Checkout</p>
              </div>
            </div>
            {/* <div className="h-[1px] w-full bg-grayMist mb-[24px]" /> */}
            <>
              {activeService?.service_details?.allow_promo && (
                <PromoCodeInput
                  addPromoCode={addPromoCode}
                  appliedDiscount={appliedDiscount}
                  setAddPromoCode={setAddPromoCode}
                  promoCode={promoCode}
                  handlePromoCodeChange={handlePromoCodeChange}
                  validatePromoCode={validatePromoCode}
                  symbol={activeService?.service_details?.symbol}
                />
              )}
              <div className="mb-[8px] flex items-center">
                <p className="min-w-fit text-base">Consultation Session</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base">
                  {activeService?.service_details &&
                    `${String.fromCharCode(
                      parseInt(
                        activeService?.service_details?.symbol?.slice(2),
                        16
                      )
                    )}${parseInt(
                      activeService?.service_details?.billing?.amount
                    ).toFixed(2)}`}
                </p>
              </div>
              <div className="mb-[8px] flex items-center">
                <p className="min-w-fit text-base">Interest or fees</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base">$0.00</p>
              </div>
              <div className="mb-[24px] flex items-center">
                <p className="min-w-fit text-[18px] font-bold">Order total</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-[18px] font-bold">
                  {activeService?.service_details &&
                    `${String.fromCharCode(
                      parseInt(activeService?.symbol?.slice(2), 16)
                    )}${(
                      parseInt(
                        activeService?.service_details?.billing?.amount
                      ) - parseInt(appliedDiscount.amount)
                    ).toFixed(2)}`}
                </p>
              </div>
            </>
          </>
        )
      case "recurring":
        return (
          <>
            <h5 className="text-graySlate mb-[16px] text-base">
              {activeService?.service_details?.allow_promo && (
                <PromoCodeInput
                  addPromoCode={addPromoCode}
                  appliedDiscount={appliedDiscount}
                  setAddPromoCode={setAddPromoCode}
                  promoCode={promoCode}
                  handlePromoCodeChange={handlePromoCodeChange}
                  validatePromoCode={validatePromoCode}
                  symbol={activeService?.service_details?.symbol}
                />
              )}
              PAYMENT SCHEDULE
            </h5>
            <div className="mb-[14px] flex items-center">
              <SvgPiechartOneQuarter className="mr-[16px]" />
              <p className="min-w-fit text-base">1st payment today</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              {/* TODO make dynamice */}
              <p className="min-w-fit text-base">
                {activeService &&
                  `${String.fromCharCode(
                    parseInt(
                      activeService?.service_details?.symbol?.slice(2),
                      16
                    )
                  )}${(
                    parseInt(activeService?.service_details?.billing?.amount) -
                    parseInt(appliedDiscount.amount)
                  ).toFixed(2)}`}
              </p>
            </div>
            <div className="mb-[12px] flex items-center">
              <SvgRecurring className="mr-[16px]" />
              <p className="min-w-fit text-base">Recurring payments</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              {/* TODO make dynamice */}
              <p className="min-w-fit text-base">
                {activeService?.service_details &&
                  `${String.fromCharCode(
                    parseInt(
                      activeService?.service_details?.symbol?.slice(2),
                      16
                    )
                  )}${parseInt(
                    activeService?.service_details?.billing?.amount
                  ).toFixed(2)}`}
              </p>
            </div>
            {/* TODO make payment schedule and credit card dynamic */}
            <p className="text-graySlate mb-[24px] text-base">
              Payments will be charged &thinsp;
              <strong className="text-black">
                {activeService?.service_details?.billing.unit === "month"
                  ? "monthly"
                  : "weekly"}
              </strong>{" "}
              &thinsp;on the anniversary of the purchase date to the payment
              method you select below
            </p>
            <div className="bg-grayMist mb-[24px] h-[1px] w-full" />
            <div className="mb-[8px] flex items-center">
              <p className="min-w-fit text-base">Interest or fees</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              {/* TODO make dynamice */}
              <p className="min-w-fit text-base">
                {String.fromCharCode(
                  parseInt(activeService?.service_details?.symbol?.slice(2), 16)
                )}
                0.00
              </p>
            </div>
            <div className="mb-[24px] flex items-center">
              <p className="min-w-fit text-base font-bold">Order total</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              {/* TODO make dynamice */}
              <p className="min-w-fit text-base font-bold">
                {`$${getRecurringTotal()}`}
              </p>
            </div>
            <div className="border-grayCloud flex items-center rounded-lg border-[1px] p-2">
              <p className="min-w-fit text-lg font-extrabold">Due Today</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              <p className="min-w-fit text-[18px] font-bold">
                {activeService &&
                  `${String.fromCharCode(
                    parseInt(
                      activeService?.service_details?.symbol?.slice(2),
                      16
                    )
                  )}${(
                    parseInt(activeService?.service_details?.billing?.amount) -
                    parseInt(appliedDiscount.amount)
                  ).toFixed(2)}`}
              </p>
            </div>
          </>
        )
      case "bundle":
        if (activeService?.service_details?.billing?.unit === "installment") {
          return (
            <>
              <h5 className="text-graySlate mb-[16px] text-base">
                PAYMENT SCHEDULE
              </h5>
              <div className="mb-[14px] flex items-center">
                <SvgPiechartOneQuarter className="mr-[16px]" />
                <p className="min-w-fit text-base">1st installment today</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base">
                  {getInstallmentAmounts().upfront}
                </p>
              </div>
              <div className="mb-[12px] flex items-center">
                <SvgPiechartThreeQuarters className="mr-[16px]" />
                <p className="min-w-fit text-base">Future installments</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base">
                  <span className="text-graySlate">
                    {
                      activeService?.service_details?.billing
                        ?.installmentIncrements?.length
                    }
                    x&nbsp;
                  </span>
                  {getInstallmentAmounts().installment}
                </p>
              </div>
              {/* TODO make payment schedule and credit card dynamic */}
              <p className="text-graySlate mb-[24px] text-base">
                Payments will be charged on the installment dates to the payment
                method you select below
              </p>
              <div className="bg-grayMist mb-[24px] h-[1px] w-full" />
              <div className="mb-[8px] flex items-center">
                <p className="min-w-fit text-base">Interest or fees</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base">
                  {String.fromCharCode(
                    parseInt(
                      activeService?.service_details?.symbol?.slice(2),
                      16
                    )
                  )}
                  0.00
                </p>
              </div>
              <div className="mb-[24px] flex items-center">
                <p className="min-w-fit text-base font-bold">Order total</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base font-bold">
                  {activeService &&
                    `${String.fromCharCode(
                      parseInt(
                        activeService?.service_details?.symbol?.slice(2),
                        16
                      )
                    )}${parseInt(
                      activeService?.service_details?.billing?.amount
                    ).toFixed(2)}`}
                </p>
              </div>
              <div className="border-grayCloud flex items-center rounded-lg border-[1px] p-2">
                <p className="min-w-fit text-lg font-extrabold">Due Today</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                <p className="min-w-fit text-[18px] font-bold">
                  {getInstallmentAmounts().upfront}
                </p>
              </div>
            </>
          )
        } else {
          return (
            <>
              <div className="mb-[62px] mt-[24px] flex items-center">
                <div className="flex flex-col">
                  <div className="border-grayMist flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                    <SvgCheck stroke="#3395FF" />
                  </div>
                  <p className="min-w-fit text-base">Select coach</p>
                </div>
                <div className="border-grayCloud mx-[24px] mb-[32px] h-[2px] w-full border-b border-dashed" />
                <div className="flex flex-col">
                  <div className="border-grayMist flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                    <SvgCheck stroke="#3395FF" />
                  </div>
                  <p className="min-w-fit text-base">Select program</p>
                </div>
                <div className="border-grayCloud mx-[24px] mb-[32px] h-[2px] w-full border-b border-dashed" />
                <div className="flex flex-col">
                  <div className="border-primaryBlue flex h-[60px] w-[60px] items-center justify-center rounded-full border">
                    <SvgCheck stroke="#3395FF" />
                  </div>
                  <p className="min-w-fit text-base">Checkout</p>
                </div>
              </div>
              {activeService?.service_details?.allow_promo && (
                <PromoCodeInput
                  addPromoCode={addPromoCode}
                  appliedDiscount={appliedDiscount}
                  setAddPromoCode={setAddPromoCode}
                  promoCode={promoCode}
                  handlePromoCodeChange={handlePromoCodeChange}
                  validatePromoCode={validatePromoCode}
                  symbol={activeService?.service_details?.symbol}
                />
              )}
              {/* <div className="h-[1px] w-full bg-grayMist mb-[24px]" /> */}
              <div className="mb-[8px] flex items-center">
                <p className="min-w-fit text-base">Session bundle</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base">
                  {activeService &&
                    `${String.fromCharCode(
                      parseInt(
                        activeService?.service_details?.symbol?.slice(2),
                        16
                      )
                    )}${parseInt(
                      activeService?.service_details?.billing?.amount
                    ).toFixed(2)}`}
                </p>
              </div>
              <div className="mb-[8px] flex items-center">
                <p className="min-w-fit text-base">Interest or fees</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-base">
                  {activeService &&
                    String.fromCharCode(
                      parseInt(
                        activeService?.service_details?.symbol?.slice(2),
                        16
                      )
                    )}
                  0.00
                </p>
              </div>
              <div className="mb-[24px] flex items-center">
                <p className="min-w-fit text-[18px] font-bold">Order total</p>
                <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
                {/* TODO make dynamice */}
                <p className="min-w-fit text-[18px] font-bold">
                  {activeService &&
                    `${String.fromCharCode(
                      parseInt(
                        activeService?.service_details?.symbol?.slice(2),
                        16
                      )
                    )}${(
                      parseInt(
                        activeService?.service_details?.billing?.amount
                      ) - parseInt(appliedDiscount.amount)
                    ).toFixed(2)}`}
                </p>
              </div>
            </>
          )
        }
      default:
        break
    }
  }

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value)
  }

  const validatePromoCode = () => {
    if (promoCode !== "") {
      ServiceRequests.validateServicePromoCode({
        promo_code: promoCode,
        coach_id: parseInt(activeContact.user_id),
      })
        .then((data) => {
          if (!data.promo_valid) {
            setAppliedDiscount({ amount: 0, promoCode: "" })
            setAddPromoCode(false)
            setPromoCode("")
            renderError(data.promo_invalid_reason)
          } else {
            const details = data.promo_code
            const discountType = details.promo_type
            const discountUnits = details.promo_value
            const promoCode = details.promo_code
            if (discountType === "percent") {
              const discountDollars =
                (discountUnits / 100) *
                activeService?.service_details?.billing?.amount
              setAppliedDiscount({
                amount: discountDollars,
                promoCode: promoCode,
              })
              renderSuccess(
                `Congrats! Your promotion code is saving you ${String.fromCharCode(
                  parseInt(activeService?.service_details?.symbol?.slice(2), 16)
                )}${discountDollars} on this payment`
              )
            } else if (discountType === "amount") {
              setAppliedDiscount({
                amount: discountUnits,
                promoCode: promoCode,
              })
              renderSuccess(
                `Congrats! Your promotion code is saving you ${String.fromCharCode(
                  parseInt(activeService?.service_details?.symbol?.slice(2), 16)
                )}${discountUnits} on this payment`
              )
            }
            setAddPromoCode(false)
          }
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
        })
    }
  }

  const createSetupIntent = async () => {
    startLoading()
    if (user) {
      await PaymentRequests.createSetupIntent({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setOptions({ clientSecret: data.stripe_client_secret })
          setPaymentMethodModal(true)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const getContacts = async () => {
    startLoading()
    await ContactRequests.getContactList({
      profile_id: parseInt(user.activeProfileId),
      profile_type: user.activeProfile,
    })
      .then((data) => {
        setContacts(data.contact_list)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const getMemberName = (member: any) => {
    let name: any = null
    if (member?.firstName) {
      if (member?.lastName) {
        name = `${member?.firstName} ${member?.lastName}`
      } else {
        name = member?.firstName
      }
    }
    return name
  }

  const generateMeetingName = (
    memberParticipant: any,
    coachParticipant: any
  ) => {
    const serviceDetails = activeService?.service_details
    const meetingName = `${coachParticipant?.contact_name} <> ${
      getMemberName(memberParticipant) || memberParticipant?.email
    }${serviceDetails ? ` // ${serviceDetails.title}` : ""}`
    return meetingName
  }

  const createMeeting = async () => {
    const coachProfileId = location.pathname.split("/")[3]
    const coachParticipant: any = contacts?.filter(
      (contact: any) => contact.profile_id === parseInt(coachProfileId)
    )[0]
    const memberParticipant = {
      user_id: parseInt(user?.user_id),
      profile_id: user?.memberProfile?.profile_id,
      email: user?.email,
      contact_name: user?.firstName
        ? `${user?.firstName} ${user?.lastName ? user?.lastName : ""}`
        : "",
      first_name: user?.firstName ? user.firstName : "",
      last_name: user?.lastName ? user.lastName : "",
    }
    const meetingName = generateMeetingName(memberParticipant, coachParticipant)
    const parsedStartTime = meetingDetails?.startTime?.includes(".")
      ? meetingDetails?.startTime.split(".")[0] + "Z"
      : meetingDetails?.startTime

    const parsedEndTime = meetingDetails?.endTime?.includes(".")
      ? meetingDetails?.endTime?.split(".")[0] + "Z"
      : meetingDetails?.endTime
    MeetingRequests.createMeeting({
      user_id: parseInt(user?.user_id),
      coach_user_id: parseInt(coachParticipant?.user_id),
      profile_id: parseInt(user.activeProfileId),
      meeting_details: {
        name: meetingName,
        meeting_type: "individual",
        start_time: parsedStartTime,
        end_time: parsedEndTime,
        medium: "zoee_video",
        status: "busy",
        reminders: [],
        participants: [memberParticipant, coachParticipant],
        service_usage_id: parseInt(serviceUsageId),
      },
    })
      .then(() => {
        setCheckoutSuccessPopup({
          show: true,
          state: {
            meetingScheduled: true,
            service: activeService,
          },
        })
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
      .finally(() => {
        stopLoading()
      })
  }

  React.useEffect(() => {
    if (contacts) {
      createMeeting()
    }
  }, [contacts])

  const [isComplete, setIsComplete] = React.useState(false)

  const handleComplete = () => {
    setIsComplete(true)
    console.log("In the checkout", isComplete)
    if (activeService?.is_group_service) {
      MeetingRequests.updateGroupMeeting({
        profile_id: user?.memberProfile?.profile_id,
        user_id: parseInt(user.user_id),
        coach_user_id: activeContact?.user_id,
        group_service_id: activeService?.group_service_id,
      })
        .then(() => {
          setCheckoutSuccessPopup({
            show: true,
            state: {
              meetingScheduled: true,
              service: activeService,
            },
          })
        })
        .finally(() => {
          setIsComplete(false)
          stopLoading()
        })
    } else {
      if (meetingDetails) {
        // IF MEETING DETAILS EXIST, CREATE MEETING THEN SHOW ENROLLED/CREATED POPUP
        getContacts()
        setIsComplete(false)
      } else {
        // IF NO MEETING DETAILS EXIST, SHOW ENROLLED POPUP WITH OPTION TO SCHEDULE
        setCheckoutSuccessPopup({
          show: true,
          state: {
            meetingScheduled: false,
          },
        })
        setIsComplete(false)
        stopLoading()
      }
    }
  }

  const submitServicePurchase = () => {
    setLoading(true)
    if (
      isTermsAgreed ||
      parseInt(activeService?.service_details?.billing?.amount) === 0
    ) {
      //  && Object.keys(selectedPaymentMethod).length > 0)
      ServiceRequests.purchaseClientService({
        user_id: parseInt(user.user_id),
        coach_user_id: activeContact?.user_id,
        member_profile_id: user?.memberProfile?.profile_id,
        coach_profile_id: activeContact?.profile_id,
        payment_method_id: selectedPaymentMethod?.stripe_payment_method_id,
        service: activeService,
        is_group_service: activeService?.is_group_service,
        group_service_id: activeService?.group_service_id,
        service_invitation_id:
          parseInt(activeService?.service_details?.service_invite_id) || null,
        promotion_code:
          appliedDiscount.promoCode === "" ? null : appliedDiscount.promoCode,
        forms: activeService?.service_details.serviceLinkedForms || [],
      })
        .then((data) => {
          // submitServicePurchase will return client-secret which would be mounted in embedded form
          if (data.session_checkout !== "free_service") {
            console.log(data)
            setClientSecret(data.session_checkout)
            setConnectedAccountID(data.connected_account_id)
            setServiceUsageId(data.service_usage_id)
            setShowCheckout(true)
          } else {
            if (meetingDetails) {
              // IF MEETING DETAILS EXIST, CREATE MEETING THEN SHOW ENROLLED/CREATED POPUP
              getContacts()
            } else {
              // IF NO MEETING DETAILS EXIST, SHOW ENROLLED POPUP WITH OPTION TO SCHEDULE
              setCheckoutSuccessPopup({
                show: true,
                state: {
                  meetingScheduled: false,
                },
              })
              setIsComplete(false)
              setLoading(false)
            }
          }
        })
        .catch((ex) => {
          console.log(ex)

          renderError(ex.response.data.message)
          setLoading(false)
        })
    } else {
      if (!isTermsAgreed) {
        renderError("You must agree to the terms to complete your purchase")
      }
    }
  }

  const getPaymentMethods = async () => {
    if (user) {
      try {
        startLoading()
        const data = await PaymentRequests.getPaymentMethods({
          user_id: parseInt(user.user_id),
        })
        setPaymentMethods(data?.payment_methods || [])
        if (data?.payment_methods?.length > 0) {
          setSelectedPaymentMethod(data.payment_methods[0])
        } else {
          setSelectedPaymentMethod({})
        }
      } catch (ex: any) {
        console.error("Error fetching or updating payment methods:", ex)
        renderError(
          ex.response?.data?.message || "Failed to fetch payment methods"
        )
      } finally {
        stopLoading()
      }
    }
  }

  const getSessionCount = () => {
    if (
      ["consultation", "individual"].includes(
        activeService?.service_details?.type
      )
    ) {
      return `${activeService?.service_details?.session?.count} session`
    } else if (["bundle"].includes(activeService?.service_details?.type)) {
      return pluralize(
        activeService?.service_details?.session?.count,
        "session"
      )
    } else if (["recurring"].includes(activeService?.service_details?.type)) {
      return (
        <div>
          {pluralize(activeService?.service_details?.session?.count, "session")}
          <span className="text-darkGray">
            {" "}
            / {`${activeService?.service_details?.billing?.unit}`}
          </span>
        </div>
      )
    } else {
      return "Not Found"
    }
  }

  const getLengthOrExpiration = () => {
    let expirationString = "No expiration"
    if (
      ["consultation", "individual", "bundle"].includes(
        activeService?.service_details?.type
      )
    ) {
      if (activeService?.service_details?.expiration?.expires) {
        expirationString = `Expires after ${activeService?.service_details?.expiration?.time} ${activeService?.service_details?.expiration?.type}`
      } else {
        expirationString = "No expiration"
      }
    } else if (["recurring"].includes(activeService?.service_details?.type)) {
      const units =
        activeService?.service_details?.expiration?.type === "weeks"
          ? "week"
          : "month"
      expirationString = `${activeService?.service_details?.expiration?.time} ${units} program`
    } else {
      expirationString = "Expiration not found"
    }
    return expirationString
  }

  const getContact = async () => {
    if (location?.state?.contact) {
      setActiveContact(location.state.contact)
    } else {
      startLoading()
      await ContactRequests.getContactList({
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile,
      })
        .then((data: any) => {
          const profileIdToMatch = parseInt(location.pathname.split("/")[3])
          const foundContact = data.contact_list.find(
            (contact: any) => contact.profile_id === profileIdToMatch
          )
          setActiveContact(foundContact)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const getService = async () => {
    if (location?.state?.service) {
      setActiveService(location.state.service)
    } else {
      const coachProfileId = parseInt(location.pathname.split("/")[3])
      startLoading()
      ServiceRequests.getClientServiceList({
        profile_id: coachProfileId,
        profile_type: "coach",
      })
        .then((data) => {
          const serviceIdToMatch = parseInt(location.pathname.split("/")[5])
          const foundService = data.service_list.find(
            (service: any) => service.service_id === serviceIdToMatch
          )
          setActiveService(foundService)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  React.useEffect(() => {
    const meetingFromStorage = localStorage.getItem("meetingToSchedule")
    const meetingToSchedule = meetingFromStorage
      ? JSON.parse(meetingFromStorage)
      : null
    if (meetingFromStorage) {
      setMeetingDetails(meetingToSchedule)
    }
    getService().then(() => {
      getContact()
    })
    setTimeout(() => {
      getPaymentMethods()
    }, 2000)
    if (meetingFromStorage) {
      localStorage.removeItem("meetingFromStorage")
    }
  }, [])

  useScrollToTop()

  return (
    <>
      {checkoutSuccessPopup?.show && (
        <CheckoutSuccessPopup
          checkoutSuccessPopup={checkoutSuccessPopup}
          contact={activeContact}
          meetingDetails={meetingDetails}
          initialSize={initialSize}
        />
      )}
      {paymentMethodModal && options && (
        <PaymentMethodModal
          options={options}
          callback={(data: boolean) => {
            getPaymentMethods()
            setPaymentMethodModal(data)
          }}
          getPaymentMethods={getPaymentMethods}
        />
      )}
      <div className="mx-auto w-full pb-24 md:max-w-[1280px]">
        <div className="mx-auto flex-col md:max-w-[1280px]">
          <h2 className="mb-[34px] mt-0 hidden text-[36px] font-bold md:block">
            {getHeaderText()}
          </h2>

          <div className="relative mt-[30px] flex w-full flex-col justify-between gap-[32px] rounded-[16px] md:flex-row md:items-start">
            <div className="flex w-full flex-col md:w-[735px]">
              <div
                className={`aspect-w-3 aspect-h-1 md:aspect-h-2 mx-auto mb-[32px] w-full rounded-[14px] ${activeService?.service_details?.image?.color} relative min-h-[140px]`}
              >
                {activeService?.service_details?.image?.exists && (
                  <img
                    src={`https://clientservice-images-${
                      process.env.REACT_APP_USER_ENVIRONMENT
                    }.s3.amazonaws.com/${
                      activeService.stripe_product_id
                    }?${new Date().getTime()}`}
                    alt="service"
                    className="aspect-[3/1] w-full rounded-[14px] object-cover"
                  />
                )}
              </div>
              <div className="mx-auto flex w-full flex-col px-[2px]">
                {/* TITLE AND PRICE */}
                <div className="mb-[20px] flex w-full items-center justify-between md:mb-[24px]">
                  <h3 className="text-[18px] font-bold">
                    {activeService?.service_details?.title}
                  </h3>
                  <h3 className="text-primaryBlue text-[22px] font-bold">
                    {getPrice(
                      parseInt(activeService?.service_details?.billing?.amount),
                      activeService?.service_details?.symbol
                    )}
                    <span className="text-graySlate text-base font-normal">
                      {getUnits(
                        activeService?.service_details?.billing?.unit,
                        parseInt(
                          activeService?.service_details?.billing?.amount
                        )
                      )}
                    </span>
                  </h3>
                </div>
                <div className="bg-grayMist mb-[16px] h-[1px] w-full"></div>
                <div className="relative mb-[32px] mr-auto flex w-full flex-col gap-[12px] text-base">
                  <div className="absolute right-0 top-0">
                    {getServiceType()}
                  </div>
                  {activeService?.service_details?.type !== "consultation" && (
                    <>
                      <div className="flex items-center gap-[12px]">
                        <SvgCheckCircle />
                        {getSessionCount()}
                      </div>
                      <div className="flex items-center gap-[12px]">
                        <SvgCheckCircle />
                        <div>
                          {parseInt(
                            activeService?.service_details?.session?.duration
                          ) / 60}{" "}
                          minutes
                          <span className="text-darkGray"> / session</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-[12px]">
                        <SvgCheckCircle />
                        {getLengthOrExpiration()}
                      </div>
                    </>
                  )}
                </div>
                <div className="bg-grayFlash mb-[18px] flex h-[32px] w-[142px] items-center justify-center rounded-[8px] font-bold">
                  Program Details
                </div>
                {activeService?.service_details?.details?.features
                  ?.feature1 && (
                  <div className="mb-[24px] mr-auto flex flex-col gap-[16px] text-base md:mb-[32px]">
                    <div className="flex items-center gap-[12px]">
                      <SvgCheckCircle />
                      {
                        activeService?.service_details?.details.features
                          .feature1
                      }
                    </div>
                    {activeService?.service_details?.details?.features
                      ?.feature2 && (
                      <div className="flex items-center gap-[12px]">
                        <SvgCheckCircle />
                        {
                          activeService?.service_details?.details.features
                            .feature2
                        }
                      </div>
                    )}
                    {activeService?.service_details?.details?.features
                      ?.feature3 && (
                      <div className="flex items-center gap-[12px]">
                        <SvgCheckCircle />
                        {
                          activeService?.service_details?.details.features
                            .feature3
                        }
                      </div>
                    )}
                  </div>
                )}
                {activeService?.service_details?.details?.description1 && (
                  <div className="mb-[40px]">
                    {activeService?.service_details?.details?.description1}
                  </div>
                )}
                <Button
                  className="mr-auto hidden md:block"
                  variant="secondary"
                  onClick={backToContactProfile}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="border-grayCloud flex flex-col border-t px-[20px] pt-[32px] md:w-[416px] md:border-l md:border-t-0 md:pl-[36px] md:pr-0 md:pt-0">
              <div className="mb-[24px] flex flex-col gap-[12px]">
                <h3 className="mb-[12px] text-[22px] font-bold">
                  {getDetailsText()}
                </h3>
                <div className="promotion-shadow relative flex flex-col rounded-[16px] bg-white px-[24px] pb-[24px] pt-[28px] md:px-[40px] md:py-[32px]">
                  <h5 className="text-grayCharcoal mb-[16px] text-base font-bold">
                    COACH
                  </h5>
                  <div className="flex w-full items-center gap-[12px]">
                    <ContactAvatar
                      contact={activeContact}
                      width={40}
                      height={40}
                      border={""}
                    />
                    <div className="text-base font-bold">
                      {activeContact?.contact_name}
                    </div>
                  </div>
                </div>
                {meetingDetails && (
                  <div className="bg-grayMist relative rounded-[16px] px-[24px] pb-[24px] pt-[28px] md:px-[40px] md:py-[32px]">
                    <h5 className="text-grayCharcoal mb-[16px] text-base font-bold">
                      WHEN
                    </h5>
                    <div>
                      {meetingDetails.startTime ? (
                        <>
                          <p className="mb-[8px] text-[16px] font-bold">
                            {`${mdDate(
                              meetingDetails.startTime
                            )}, ${userTimezone}`}
                          </p>
                          <div className="text-graySlate flex gap-[4px] text-base">
                            <p className="font-bold text-black">
                              &nbsp;
                              {moment(meetingDetails.startTime).format(
                                "h:mm A"
                              )}
                            </p>
                            -
                            <p className="font-bold text-black">
                              &nbsp;
                              {moment(meetingDetails.endTime).format("h:mm A")}
                            </p>
                          </div>
                        </>
                      ) : (
                        <p className="text-vividOrange text-base">
                          No date selected
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* SHOW PAYMENT METHOD SELECTOR IF PAID SERVICE */}
              {parseInt(activeService?.service_details?.billing?.amount) > 0 ? (
                <>
                  <h3 className="mb-[12px] text-[22px] font-bold">
                    Payment Details
                  </h3>
                  {getPaymentDetails()}
                  <div
                    className={`mb-[24px] h-[1px] w-full ${
                      activeService?.service_details?.type === "recurring" ||
                      activeService?.service_details?.type === "bundle"
                        ? ""
                        : "bg-grayMist"
                    }`}
                  />
                  <h5 className="text-graySlate mb-[8px] text-base">
                    EXPIRATION
                  </h5>
                  {/* TODO make expiration date dynamic */}
                  <p className="text-graySlate mb-[24px] text-base">
                    {activeService?.service_details?.expiration?.expires ? (
                      <>
                        {getExpirationText()}&nbsp;
                        <span className="text-mainBlack">
                          {getExpirationDate()}
                        </span>
                      </>
                    ) : (
                      <>This service has no expiration</>
                    )}
                  </p>
                  <div className="bg-grayMist mb-[24px] h-[1px] w-full" />
                  <div className="mb-[24px] flex gap-[16px]">
                    <div className="">
                      {isTermsAgreed ? (
                        <button
                          onClick={() => setIsTermsAgreed(() => !isTermsAgreed)}
                        >
                          <SvgCheckbox />
                        </button>
                      ) : (
                        <button
                          onClick={() => setIsTermsAgreed(() => !isTermsAgreed)}
                        >
                          <SvgCheckboxOff stroke="#4750F5" strokeWidth="1" />
                        </button>
                      )}
                    </div>
                    <div className="text-graySlate text-base">
                      I have read and agree to the&nbsp;
                      <a
                        href="https://zoee.com/terms-and-conditions"
                        className="text-blurple"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Agreement &amp; Disclosures
                      </a>
                      &nbsp;and&nbsp;
                      <a
                        href="https://intercom.help/zoee/en/articles/8623117-how-does-zoee-protect-your-information-on-stripe"
                        className="text-blurple"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Payment Authorization
                      </a>
                      , and the
                      {/* TODO add href */}
                      <a href="#" className="text-blurple">
                        &nbsp;refund policy&nbsp;
                      </a>
                      provided by Zoee
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {showCheckout && (
                <SessionCheckout
                  clientSecret={clientSecret}
                  connectedAccountID={connectedAccountID}
                  handleComplete={handleComplete}
                />
              )}

              {!showCheckout && (
                <Button
                  className="mb-[12px]"
                  size="lg"
                  onClick={submitServicePurchase}
                  disabled={!isTermsAgreed && parseInt(activeService?.service_details?.billing?.amount) !== 0}
                >
                  {loading ? (
                    <Loader />
                  ) : activeService?.service_details?.type ===
                    "consultation" ? (
                    <>Schedule Consultation</>
                  ) : parseInt(
                      activeService?.service_details?.billing?.amount
                    ) === 0 ? (
                    <>Confirm & Book</>
                  ) : (
                    <>Continue to Purchase</>
                  )}
                </Button>
              )}

              {Boolean(
                parseInt(activeService?.service_details?.billing?.amount) > 0
              ) && (
                <div className="text-muted-foreground mx-auto flex items-center gap-2 text-base">
                  <SvgLock2 className="h-4 w-4" />
                  <p>Guaranteed safe &amp; secure payments</p>
                </div>
              )}

              <Button
                className="mx-auto mr-auto mt-[22px] w-full md:hidden"
                variant="secondary"
                onClick={backToContactProfile}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgramCheckout

import React from "react"
import StepNav from "./StepNav"
import InviteContacts from "./step-views/InviteContacts"
import SelectService from "./step-views/SelectService"
import CheckAvailability from "./step-views/CheckAvailability"
import ConfirmSend from "./step-views/ConfirmSend"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { ContactRequests, CalendarRequests } from "../../../api/app.service"
import { Meeting, MeetingPrefillOptions } from "../../../types/meetingTypes"
import { AvailabilityRulesData } from "../../../types/availabilityTypes"
import BackToButton from "../../BackToButton"
import { getVideoTracksFromPeers } from "@100mslive/react-sdk/dist/utils/layout"

type Props = {
  // mandatory props for edit and new
  newMeetingType: "individual" | "group" | "custom"
  setNewMeetingType: any
  // used for pre-populating new meeting on links from other pages e.g. Schedule Session from ServicePreviewElement.tsx
  prefillOptions?: MeetingPrefillOptions | null
  // include following 2 props when using this component as new meeting interface from Scheduling.tsx
  setShowNewMeeting?: any
  setShowCalendar?: any
  // include following 4 props when using this component as edit meeting interface from ZoeeSessionDetailView.tsx
  existingMeetingData?: Meeting
  initialStep?: string
  isEditing?: boolean
  setActiveSessionDetailViewId?: any
  showRescheduleMeeting: boolean
  setShowRescheduleMeeting: any
  clickReschedule: boolean
  setClickReschedule: any
  clickTodayReschedule: boolean
  backToContacts: boolean
  contact: any
  keepBackToButton: boolean
}

const NewMeeting: React.FC<Props> = ({
  newMeetingType,
  setNewMeetingType,
  setShowNewMeeting,
  setShowCalendar,
  existingMeetingData,
  initialStep,
  isEditing,
  setActiveSessionDetailViewId,
  prefillOptions,
  showRescheduleMeeting,
  setShowRescheduleMeeting,
  clickReschedule,
  clickTodayReschedule,
  backToContacts,
  contact,
  keepBackToButton,
}) => {
  const [step, setStep] = React.useState<number>(1)
  const { user } = React.useContext(UserCtx)
  const userActiveProfile = user.activeProfile
  const { renderError } = React.useContext(CommonFunctionCtx)
  const [userContacts, setUserContacts] = React.useState<any>([])
  const [userAvailabilities, setUserAvailabilities] = React.useState<
    Array<AvailabilityRulesData>
  >([])
  const [loadingNewMeeting, setLoadingNewMeeting] =
    React.useState<boolean>(false)

  const [newMeetingData, setNewMeetingData] = React.useState<Meeting>({
    id: null,
    meetingType: newMeetingType,
    type: null,
    inProgress: false,
    contacts: [],
    meetingName: null,
    status: "busy",
    medium: "zoee_video",
    date: null,
    startTime: null,
    endTime: null,
    duration: null,
    service_usage_id: null,
    timezone: user.timezone || null,
    service_details: null,
    notifications: [],
    calendar: "zoee",
    stripe_product_id: null,
  })

  const getContacts = () => {
    if (user?.coachProfile) {
      setLoadingNewMeeting(true)
      ContactRequests.getContactList({
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setUserContacts(data.contact_list)
          setLoadingNewMeeting(false)
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
          setLoadingNewMeeting(false)
        })
    }
  }

  const getAvailabilityRules = () => {
    
    console.log(contact, prefillOptions, existingMeetingData)
    let coachUserId
    if(user.isCoach){
      // temp fix, to be removed for coach to coach connection as there will not be multiple profiles
      if(user?.coachProfile?.length > 0){
      coachUserId = user.user_id
      } else if(user?.memberProfile?.length > 0){
        if(prefillOptions) coachUserId = prefillOptions?.contact?.user_id
        if(contact) coachUserId = contact?.user_id
        if(existingMeetingData) coachUserId = existingMeetingData?.contacts?.find(contact => contact.is_creator).user_id
      }
    } else{
        if(prefillOptions) coachUserId = prefillOptions?.contact?.user_id
        if(contact) coachUserId = contact?.user_id
        if(existingMeetingData) coachUserId = existingMeetingData?.contacts?.find(contact => contact.is_creator).user_id
    }
    
    if (coachUserId) {
     
      setLoadingNewMeeting(true)
      CalendarRequests.getAvailabilityRules({
        user_id: parseInt(coachUserId),
      })
        .then((data) => {
          // if (data.availability_rules.length > 0) {
          setUserAvailabilities(data.availability_rules)
          // TODO add logic for checking for default schedule
          // setSelectedSchedule(data.availability_rules[0]);
          // }
          setLoadingNewMeeting(false)
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
          setLoadingNewMeeting(false)
        })
    }
  }

  const handleEditClick = (stepName: string) => {
    if (newMeetingType === "individual") {
      switch (stepName) {
        case "attendees":
          setStep(1)
          break
        case "services":
          setStep(2)
          break
        case "availability":
          setStep(3)
          break
        default:
          break
      }
    } else if (newMeetingType === "group") {
      switch (stepName) {
        case "services":
          setStep(1)
          break
        case "attendees":
          setStep(2)
          break
        case "availability":
          setStep(3)
          break
        default:
          break
      }
    } else {
      switch (stepName) {
        case "attendees":
          setStep(1)
          break
        case "availability":
          setStep(2)
          break
        default:
          break
      }
    }
  }

  React.useEffect(() => {
    if (prefillOptions?.contact && prefillOptions?.service) {
      setNewMeetingType("individual")
      setStep(3)
      const updatedContacts = newMeetingData.contacts
      updatedContacts.push(prefillOptions.contact)
      setNewMeetingData({
        ...newMeetingData,
        contacts: updatedContacts,
        duration: prefillOptions.service.service_details.session.duration / 60,
        service_details: prefillOptions.service.service_details,
        service_usage_id: prefillOptions.service.service_usage_id,
        stripe_product_id: prefillOptions.service.stripe_product_id,
      })
    }

    if (prefillOptions?.contact && !prefillOptions?.service) {
      setNewMeetingType("individual")
      setStep(2)
      const updatedContacts = newMeetingData.contacts
      updatedContacts.push(prefillOptions.contact)
      setNewMeetingData({
        ...newMeetingData,
        contacts: updatedContacts,
      })
    }
  }, [prefillOptions])

  React.useEffect(() => {
    getContacts()
    getAvailabilityRules()
  }, [user])

  React.useEffect(() => {
    if (existingMeetingData) {
      setNewMeetingData(existingMeetingData)
      setNewMeetingType(existingMeetingData.meetingType)
    }

    if (initialStep) {
      handleEditClick(initialStep)
    }
  }, [existingMeetingData, initialStep])

  const marginTop = clickTodayReschedule
    ? "-mt-0 md:-mt-[55px]"
    : clickReschedule
    ? "-mt-0 md:-mt-[12px]"
    : "-mt-0 md:-mt-[67px]"

  return (
    <>
      <div className="mt-[12px] flex w-full flex-col items-center">
        <div className={`w-full max-w-[1128px] md:px-[16px] ${marginTop}`}>
          <div>
            {!showRescheduleMeeting ? (
              !existingMeetingData ? (
                <div
                  onClick={() => {
                    setShowNewMeeting(false)
                    setShowCalendar(true)
                  }}
                  className="mb-[24px] w-screen px-[12px] md:w-auto md:px-0"
                >
                  {keepBackToButton ? (
                    <BackToButton
                      to={`/${userActiveProfile}/scheduling`}
                      current="New Meeting"
                      prev="Calendar"
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => {
                    setShowNewMeeting(false)
                    setActiveSessionDetailViewId(existingMeetingData.id)
                  }}
                  className="mb-[24px] w-screen px-[12px] md:w-auto md:px-0"
                >
                  {backToContacts ? (
                    <BackToButton
                      to={`/${userActiveProfile}/contacts/${contact.profile_id}`}
                      current="Reschedule Meeting"
                      prev="Meeting Details"
                    />
                  ) : (
                    <BackToButton
                      to={`/${userActiveProfile}/scheduling`}
                      current="Reschedule Meeting"
                      prev="Meeting Details"
                    />
                  )}
                </div>
              )
            ) : (
              <div
                onClick={() => {
                  setShowRescheduleMeeting(false)
                  setActiveSessionDetailViewId(null)
                }}
                className="mb-[24px] w-screen px-[12px] md:w-auto md:px-0"
              >
                {backToContacts ? (
                  <BackToButton
                    to={`/${userActiveProfile}/contacts/${contact.profile_id}`}
                    current="Reschedule Meeting"
                    prev="Contact Profile"
                  />
                ) : (
                  <BackToButton
                    to={`/${userActiveProfile}/scheduling`}
                    current="Reschedule Meeting"
                    prev="Calendar"
                  />
                )}
              </div>
            )}
          </div>

          <div className="flex w-full justify-center md:max-w-[1128px] md:gap-[64px] md:px-[16px]">
            {/* <StepNav
              step={step}
              setStep={setStep}
              newMeetingType={newMeetingType}
            /> */}
            <div className="w-[360px] md:w-[598px] lg:w-[798px] mx-auto">
              {newMeetingType === "individual" && (
                <>
                  {step === 1 && (
                    <InviteContacts
                      userContacts={userContacts}
                      setStep={setStep}
                      newMeetingType={newMeetingType}
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      isLoading={loadingNewMeeting}
                    />
                  )}
                  {step === 2 && (
                    <SelectService
                      setNewMeetingType={setNewMeetingType}
                      newMeetingData={newMeetingData}
                      isEditing={isEditing}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                      setShowNewMeeting={setShowNewMeeting}
                      setActiveSessionDetailViewId={setActiveSessionDetailViewId}
                      existingMeetingData={existingMeetingData}
                    />
                  )}
                  {step === 3 && (
                    <CheckAvailability
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                      availabilities={userAvailabilities}
                    />
                  )}
                  {step === 4 && (
                    <ConfirmSend
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                      newMeetingType={newMeetingType}
                      isEditing={isEditing}
                      setShowCalendar={setShowCalendar}
                      setShowNewMeeting={setShowNewMeeting}
                    />
                  )}
                </>
              )}
              {newMeetingType === "group" && (
                <>
                  {step === 1 && (
                    <SelectService
                      setNewMeetingType={setNewMeetingType}
                      newMeetingData={newMeetingData}
                      isEditing={isEditing}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                    />
                  )}
                  {step === 2 && (
                    <InviteContacts
                      userContacts={userContacts}
                      setStep={setStep}
                      newMeetingType={newMeetingType}
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      isLoading={loadingNewMeeting}
                    />
                  )}
                  {step === 3 && (
                    <CheckAvailability
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                      availabilities={userAvailabilities}
                    />
                  )}
                  {step === 4 && (
                    <ConfirmSend
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                      newMeetingType={newMeetingType}
                      isEditing={isEditing}
                      setShowCalendar={setShowCalendar}
                      setShowNewMeeting={setShowNewMeeting}
                    />
                  )}
                </>
              )}
              {newMeetingType === "custom" && (
                <>
                  {step === 1 && (
                    <InviteContacts
                      userContacts={userContacts}
                      setStep={setStep}
                      newMeetingType={newMeetingType}
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      isLoading={loadingNewMeeting}
                    />
                  )}
                  {step === 2 && (
                    <CheckAvailability
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                      availabilities={userAvailabilities}
                    />
                  )}
                  {step === 3 && (
                    <ConfirmSend
                      newMeetingData={newMeetingData}
                      setNewMeetingData={setNewMeetingData}
                      setStep={setStep}
                      newMeetingType={newMeetingType}
                      isEditing={isEditing}
                      setShowCalendar={setShowCalendar}
                      setShowNewMeeting={setShowNewMeeting}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewMeeting

import React, { Fragment } from "react"
import ServiceCardPreview from "../../services/ServiceCardPreview"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { MeetingRequests, ServiceRequests } from "../../../api/app.service"
import { Link, useNavigate } from "react-router-dom"
import ServiceUsagePopup from "./ServiceUsagePopup"
import useLoading from "../../../hooks/useLoading"
import Loader from "../../ui/loader"
import { Button } from "../../ui/button"
import { Badge } from "../../ui/badge"

interface Props {
  contact: any
}

const ServicesPreviewElement: React.FC<Props> = ({ contact }) => {
  const [servicesLoading, setServicesLoading] = React.useState<boolean>(false)
  const [serviceUsage, setServiceUsage] = React.useState<any>({
    show: false,
    service: {},
  })
  const [selectedState, setSelectedState] = React.useState<string>("active")
  const { user } = React.useContext(UserCtx)
  const [services, setServices] = React.useState<any[]>([])
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const navigate = useNavigate()
  const { endpoint_slug } = contact

  const handleServiceEnrollClick = (service: any) => {
    const updatedServices = services.map((s) => {
      if (s.service_id === service.service_id) {
        return { ...s, service_status: "active" }
      }
      return s
    })
    setServices(updatedServices)
    setSelectedState("active")
    navigate(`/${user.activeProfile}/contacts/${contact.profile_id}/services`, {
      state: { service },
    })
    renderSuccess("Service invite accepted successfully")
    handleRevokeServiceInvite(service, false)
  }

  const handleScheduleSessionClick = (service: any, contact: any) => {
    navigate(`/${user.activeProfile}/scheduling`, {
      state: { service, contact },
    })
  }

  const getProfileServiceList = () => {
    if (user) {
      setServicesLoading(true)
      ServiceRequests.getProfileServiceList({
        coach_profile_id: parseInt(
          user.activeProfile === "coach"
            ? user.activeProfileId
            : contact.profile_id
        ),
        member_profile_id: parseInt(
          user.activeProfile === "coach"
            ? contact.profile_id
            : user.activeProfileId
        ),
      })
        .then((data) => {
          setServicesLoading(false)
          setServices(data.service_list)
        })
        .catch((ex) => {
          setServicesLoading(false)
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  const handleRevokeServiceInvite = (service: any, showMessage = true) => {
    startLoading()
    ServiceRequests.revokeServiceInvite({
      invite_id: parseInt(service.service_invite_id),
    })
      .then((data) => {
        if (showMessage) {
          renderSuccess(data.message)
        }
        getProfileServiceList()
        stopLoading()
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const handleDenyServiceInvite = (service: any) => {
    startLoading()
    ServiceRequests.denyServiceInvite({
      invite_id: service.service_invite_id,
      coach_user_id: contact.user_id,
      client_user_id: +user.user_id
    })
      .then((data) => {
        renderSuccess(data.message)
        getProfileServiceList()
        stopLoading()
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  React.useEffect(() => {
    getProfileServiceList()
  }, [])

  return (
    <>
      {serviceUsage.show && (
        <ServiceUsagePopup
          setServiceUsage={setServiceUsage}
          serviceUsage={serviceUsage}
        />
      )}

      <div className="w-full main-shadow rounded-[16px] px-[16px] py-[24px] md:p-[40px] relative">
        <div className="w-full flex flex-col md:flex-row items-center justify-between mb-[20px]">
          <h2 className="hidden md:block font-semibold text-[28px]">
            My Services
          </h2>
          <div className="md:hidden w-full flex items-center justify-between mb-[24px]">
            <h2 className="font-bold text-[18px]">Services</h2>
            <Button asChild className="text-md">
              <Link
                to={{
                  pathname:
                    user.activeProfile === "coach"
                      ? `/${user.activeProfile}/contacts/${contact.profile_id}/services`
                      : `/find-a-coach/${endpoint_slug}`,
                  search:
                    user.activeProfile !== "coach" ? "?services=true" : "",
                }}
                className="h-[32px] min-w-fit"
              >
                {user.activeProfile === "coach"
                  ? "Invite to Service"
                  : "See Available Services"}
              </Link>
            </Button>
          </div>
          <div className="flex items-center gap-[24px] mb-[26px] md:mb-0">
            <div className="flex items-center p-[4px] bg-hover rounded-[12px]">
              <button
                className={`flex items-center justify-center font-bold text-base rounded-[8px]
                                ${
                                  selectedState === "active"
                                    ? "text-mainBlack bg-white attendee-toggle-shadow"
                                    : "text-gray"
                                } py-[4px] px-[24px]`}
                onClick={() => setSelectedState("active")}
              >
                Active
              </button>
              <button
                className={`flex items-center justify-center font-bold text-base rounded-[8px]
                                ${
                                  selectedState === "past"
                                    ? "text-mainBlack bg-white attendee-toggle-shadow"
                                    : "text-gray"
                                } py-[4px] px-[24px]`}
                onClick={() => setSelectedState("past")}
              >
                Past
              </button>
              <button
                className={`flex items-center justify-center font-bold text-base rounded-[8px]
                                ${
                                  selectedState === "pendingInvite"
                                    ? "text-mainBlack bg-white attendee-toggle-shadow"
                                    : "text-gray"
                                } py-[4px] px-[24px]`}
                onClick={() => setSelectedState("pendingInvite")}
              >
                {user.activeProfile === "coach" ? "Sent Invites" : "Pending"}
                {services.filter((s: any) => s.service_status === "pending")
                  .length > 0 ? (
                  <Badge variant="alert" className="ml-1">
                    {
                      services.filter(
                        (s: any) => s.service_status === "pending"
                      ).length
                    }
                  </Badge>
                ) : (
                  <></>
                )}
              </button>
            </div>
            <Link
              to={{
                pathname:
                  user.activeProfile === "coach"
                    ? `/${user.activeProfile}/contacts/${contact.profile_id}/services`
                    : `/find-a-coach/${endpoint_slug}`,
                search: user.activeProfile !== "coach" ? "?services=true" : "",
              }}
              className="hidden md:flex btn-primary btn-blue bg-blurple rounded-md h-[32px] min-w-fit"
            >
              {user.activeProfile === "coach"
                ? "Invite to Service"
                : "See Available Services"}
            </Link>
          </div>
        </div>
        {servicesLoading ? (
          <div
            className="w-full h-[464px] flex items-center justify-center flex-col
                        rounded-[16px] border border-grayMist bg-[#f8f8f8] gap-[10px]"
          >
            <span className="mb-2">Loading services...</span>
            <Loader />
          </div>
        ) : (
          <div className="flex flex-wrap justify-center md:justify-between w-full gap-[24px]">
            {selectedState === "active" && (
              <>
                {services.map((service: any) => {
                  if (service.service_status === "active") {
                    return (
                      <Fragment key={service.name}>
                        {/* MOBILE */}

                        <div className="md:hidden">
                          <ServiceCardPreview
                            service={service}
                            buttonText={
                              service.is_group_service
                                ? "Meetings are Scheduled"
                                : "Schedule Session"
                            }
                            buttonCallback={() =>
                              handleScheduleSessionClick(service, contact)
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                          />
                        </div>

                        {/* DESKTOP */}
                        <div className="hidden md:block w-[48%]">
                          <ServiceCardPreview
                            service={service}
                            buttonText={
                              service.is_group_service
                                ? "Meetings are Scheduled"
                                : "Schedule Session"
                            }
                            buttonCallback={() =>
                              handleScheduleSessionClick(service, contact)
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                            markCompletedButton={
                              <>
                                {user.activeProfile === "coach" &&
                                  service.available_session_count > 0 && (
                                    <Button
                                      variant="ghost"
                                      size="lg"
                                      onClick={() => {
                                        MeetingRequests.markSessionCompleted({
                                          user_id: contact?.user_id,
                                          profile_id: contact?.profile_id,
                                          coach_user_id: parseInt(
                                            user?.user_id
                                          ),
                                          meeting_details: {
                                            service_usage_id:
                                              service?.service_usage_id,
                                            participants: [
                                              {
                                                user_id: user?.user_id,
                                                profile_id:
                                                  user?.coachProfile.profile_id,
                                                email: user?.email,
                                              },
                                              {
                                                user_id: contact?.user_id,
                                                profile_id: contact?.profile_id,
                                                email: contact?.email,
                                              },
                                            ],
                                          },
                                        })
                                          .then(() => {
                                            getProfileServiceList()
                                            renderSuccess(
                                              "Session marked as completed"
                                            )
                                          })
                                          .catch((ex) => {
                                            renderError(
                                              ex.response.data.message
                                            )
                                          })
                                      }}
                                    >
                                      Mark Completed
                                    </Button>
                                  )}
                              </>
                            }
                          />
                        </div>
                      </Fragment>
                    )
                  } else {
                    return <></>
                  }
                })}
                {services.filter(
                  (service) => service.service_status === "active"
                ).length === 0 && (
                  <div
                    className="w-full h-[496px] flex items-center justify-center 
                                        rounded-[16px] border border-grayMist bg-[#f8f8f8]"
                  >
                    No Active Services
                  </div>
                )}
              </>
            )}
            {selectedState === "past" && (
              <>
                {services.map((service: any, idx: number) => {
                  if (service.service_status === "past") {
                    return (
                      <Fragment key={service.name}>
                        {/* MOBILE */}
                        <div className="md:hidden">
                          <ServiceCardPreview
                            service={service}
                            buttonText="Schedule Session"
                            buttonCallback={() =>
                              handleScheduleSessionClick(service, contact)
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                          />
                        </div>

                        {/* DESKTOP */}
                        <div className="hidden md:block w-[48%]">
                          <ServiceCardPreview
                            service={service}
                            buttonText="Schedule Session"
                            buttonCallback={() =>
                              handleScheduleSessionClick(service, contact)
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                          />
                        </div>
                      </Fragment>
                    )
                  } else {
                    return <></>
                  }
                })}
                {services.filter((service) => service.service_status === "past")
                  .length === 0 && (
                  <div
                    className="w-full h-[496px] flex items-center justify-center 
                                        rounded-[16px] border border-grayMist bg-[#f8f8f8]"
                  >
                    No Past Services
                  </div>
                )}
              </>
            )}
            {selectedState === "pendingInvite" && (
              <>
                {services
                  .filter((service) => service.service_status === "pending")
                  .map((service: any, idx: number) => (
                    <Fragment key={service.name}>
                      {/* MOBILE */}
                      <div className="md:hidden">
                        <ServiceCardPreview
                          service={service}
                          buttonText={
                            user.activeProfile === "coach"
                              ? "Revoke Invite"
                              : "Enroll In Service"
                          }
                          buttonCallback={() => {
                            if (user.activeProfile === "coach") {
                              handleRevokeServiceInvite(service)
                            } else {
                              handleServiceEnrollClick(service)
                            }
                          }}
                          secondaryButtonCallback={() =>
                            handleDenyServiceInvite(service)
                          }
                        />
                      </div>
                      {/* DESKTOP */}
                      <div className="hidden md:block">
                        <ServiceCardPreview
                          service={service}
                          buttonText={
                            user.activeProfile === "coach"
                              ? "Revoke Invite"
                              : "Enroll In Service"
                          }
                          buttonCallback={() => {
                            if (user.activeProfile === "coach") {
                              handleRevokeServiceInvite(service)
                            } else {
                              handleServiceEnrollClick(service)
                            }
                          }}
                          secondaryButtonCallback={() =>
                            handleDenyServiceInvite(service)
                          }
                        />
                      </div>
                    </Fragment>
                  ))}
                {services.filter(
                  (service) => service.service_status === "pending"
                ).length === 0 && (
                  <div
                    className="w-full h-[496px] flex items-center justify-center 
                            rounded-[16px] border border-grayMist bg-[#f8f8f8]"
                  >
                    No Pending Services
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ServicesPreviewElement

import { useState, useEffect, FC, ReactNode } from "react"
import { faqs } from "../../../data/faqData"
import { motion, useAnimation, AnimatePresence } from "framer-motion"

const FAQ: FC = () => {
  return (
    <div className="max-w-[1232px] mx-auto md:px-[48px]">
      {faqs.map((faq, index) => (
        <FAQItem
          key={faq.id}
          title={faq.title}
          content={faq.content}
          isOpen={index === 0} // Open the first FAQ item by default
        />
      ))}
    </div>
  )
}

export default FAQ

type FAQItemProps = {
  title: string
  content: ReactNode
  isOpen?: boolean
}

const FAQItem: FC<FAQItemProps> = ({ title, content, isOpen = false }) => {
  const [showContent, setShowContent] = useState<boolean>(isOpen)

  const controls = useAnimation()

  const variants = {
    expanded: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 },
  }

  useEffect(() => {
    if (showContent) {
      controls.start("expanded")
    } else {
      controls.start("collapsed")
    }
  }, [showContent])

  return (
    <motion.div
      onClick={() => setShowContent(() => !showContent)}
      className="py-[54px] mx-[24px] cursor-pointer border-b border-gray last:border-0"
    >
      <div className="flex justify-between items-center">
        <h3 className="font-semibold md:text-[32px] md:leading-[36px] text-[26px] leading-[32px]">
          {title}
        </h3>
        <div className="text-[28px] font-light">{showContent ? "-" : "+"} </div>
      </div>
      <AnimatePresence>
        {showContent && (
          <>
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 36 }}
              exit={{ height: 0 }}
              transition={{ duration: 0.3 }}
            ></motion.div>
            <motion.div
              className="text-[18px] overflow-hidden"
              initial="collapsed"
              animate={controls}
              transition={{ duration: 0.3 }}
              variants={variants}
              exit={variants.collapsed}
            >
              {content}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
